import React, { useEffect } from "react"
import ActesList from "../components/actes/ActesList.tsx"
import { setMetaTags } from "../data/helpers.tsx";

const Home: React.FC<{}> = () => {

  useEffect(() => {
    setMetaTags(
      "Actes d'état civil en ligne | acte-naissance-en-ligne.fr",
      "Obtenez vos actes d'état civil en ligne facilement : acte de naissance, acte de mariage, ou acte de décès en quelques clics. Service rapide et simple.",
      'https://www.acte-naissance-en-ligne.fr'
    );
  }, []);

  return (
    <div className="w-full flex flex-col items-center justify-center text-customBlue mb-12">
      <div className="flex flex-col gap-3">
        <h1 className="text-md md:text-lg px-12 py-4 text-center"> Gagnez du Temps ! Commandez <strong> vos actes d'état civil en ligne </strong> en toute simplicité. </h1>
        <ActesList />
        <div className="md:w-4/5 mx-auto flex flex-col gap-3 p-4">
          <div> Accès à nos services 24/7, sans contrainte de déplacement. </div>
          <div>
            Ne perdez plus votre temps en mairie où les temps d’attente sont très longs et les horaires d’ouverture limités.
          </div>
          <div>
            Notre plateforme <a href="https://www.acte-naissance-en-ligne.fr" className="underline"> <strong> acte-naissance-en-ligne.fr </strong> </a>  vous offre la possibilité de <b> commander en ligne </b> vos actes <b> d'états civils </b> avec un focus principal sur les <strong> actes de naissances </strong>, les <strong> actes de mariage </strong> et les <strong> actes de décès </strong>.
          </div>
          <div>
            Nous vous accompagnons dans chaque étape du processus avec nos <b> formulaires intuitifs </b>.
          </div>
          <div>
            Vous n’avez plus qu’à renseigner les informations essentielles pour l’obtention de vos actes d’états civils et nous nous occupons du reste !
          </div>
          <div>
            Plus besoin de vous déplacer à plusieurs reprises pour constituer un dossier de mariage, obtenir vos papiers d’identité, entamer une démarche d’adoption, une demande de visa, une succession, un changement de nom…
          </div>
          <div>
            Gagnez un temps précieux et simplifiez vos <b> démarches administratives </b> avec notre plateforme de commande en ligne <a href="https://www.acte-naissance-en-ligne.fr" className="underline"> acte-naissance-en-ligne.fr</a>
          </div>
        </div>
      </div>
      <div className="w-full md:w-4/5 flex flex-col gap-3 text-customBlue p-4 rounded">
        <h2 className="font-bold"> Pourquoi Choisir Nos Services ? </h2>
        <div>
          Dans un monde où les démarches administratives peuvent être complexes et
          chronophages, nous vous offrons une solution simple et efficace pour obtenir
          vos actes d&#39;état civil. Que vous ayez besoin d&#39;un acte de naissance, d&#39;un acte
          de décès ou d&#39;un acte de mariage, notre plateforme vous guide à chaque
          étape du processus.
        </div>
        <h3> Un Service Rapide et Fiable </h3>
        <div>
          Nous comprenons l&#39;importance de votre temps. Grâce à notre service en ligne,
          vous pouvez demander vos actes d&#39;état civil en quelques clics. Notre équipe
          s&#39;assure que votre demande soit traitée rapidement et efficacement.
        </div>
        <h3>
          Assistance personnalisée
        </h3>
        <div>
          A Nous vous offrons un accompagnement personnalisé pour tous les profils
          de demandeurs. Que vous soyez un citoyen français né en France ou à
          l’étranger, nous vous proposons une assistance à chaque étape de votre
          demande d&#39;acte de naissance. Nous adaptons notre service pour répondre
          précisément à vos besoins administratifs spécifiques.
        </div>
        <h2>
          Nos Services
        </h2>
        <h3> Demande d&#39;Acte de Naissance </h3>
        <div>
          Obtenir un acte de naissance est une démarche administrative essentielle.
          Plusieurs méthodes existent : via la mairie de votre lieu de naissance, par
          correspondance, ou en ligne sur des plateformes sécurisées comme <b>acte-
            naissance-en-ligne.fr.</b>  La demande d&#39;acte de naissance nécessite de fournir
          des justificatifs précis et de suivre une procédure administrative rigoureuse.
        </div>
        <h3> Pourquoi Demander un Acte de Naissance ? </h3>
        <div>
          L&#39;acte de naissance est un document administratif indispensable dans
          plusieurs situations :
        </div>
        <ul className="list-disc flex flex-col gap-3">
          <li> Acte de naissance pour Mariage </li>
          <li> Acte de naissance pour Passeport </li>
          <li> Acte de naissance pour Carte d&#39;identité </li>
          <li> Acte de naissance pour Retraite </li>
          <li> Acte de naissance pour PACS </li>
          <li> Acte de naissance pour Adoption </li>
          <li> Acte de naissance pour Succession </li>
          <li> Acte de naissance pour Changement de nom </li>
        </ul>
        <h2> Comment Demander Votre Acte de Naissance en Ligne ? </h2>
        <ul className="list-disc flex flex-col gap-3">
          <li> 1. Remplissez notre formulaire </li>
          <li> 2. Renseignez vos informations personnelles </li>
          <li> 3. Choisissez le type d&#39;acte souhaité </li>
          <li> 4. Validez votre commande </li>
          <li> 5. Recevez votre document par courrier </li>
        </ul>
        <div>
          Les délais varient selon le type d&#39;acte demandé. En général, vous recevrez
          votre document dans un délai de 5 à 10 jours ouvrés.
        </div>
        <h3> Demande d&#39;Acte de Décès </h3>
        <div>
          L&#39;acte de décès est nécessaire pour régler les affaires administratives suite à
          un décès. Il peut être demandé par les proches ou les héritiers.
        </div>
        <div> Processus simplifié : </div>
        <div>
          Utilisez notre service en ligne pour demander un acte de décès en
          fournissant les informations sur la personne décédée.
        </div>
        <b> Qui Peut Demander un Acte de Décès ? </b>
        <div>
          La demande d&#39;un acte de décès n&#39;est pas ouverte à tous. Sont principalement
          habilités à le recevoir les membres de la famille directe, le conjoint survivant,
          les ayants droit légaux et certaines administrations dans le cadre de
          procédures spécifiques. Chaque situation personnelle peut nécessiter des
          justificatifs différents.
        </div>
        <h3> Demande d&#39;Acte de Mariage </h3>
        <div>
          - L&#39;acte de mariage est indispensable pour diverses démarches
          administratives, comme le changement de nom ou la déclaration fiscale.
        </div>
        <div> Comment procéder ? </div>
        <div>
          - Complétez notre formulaire en ligne avec les détails nécessaires (noms
          des époux, date du mariage) et nous traiterons votre demande rapidement.
        </div>
        <h2> Contactez-Nous </h2>
        <div>
          Pour toute question ou assistance supplémentaire concernant vos demandes
          d&#39;actes d&#39;état civil, n&#39;hésitez pas à nous contacter via notre formulaire en ligne.
          Notre équipe est là pour vous aider !
        </div>
      </div>
    </div>
  )
}

export default Home