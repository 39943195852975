import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { resetActAddress, resetBirthInfo, resetDeathInfo, resetMarriageInfo, useTypedDispatch } from '../store/index.ts';

const PaymentSuccess = () => {
  const dispatch = useTypedDispatch()
  const location = useLocation()
  const [orderNumber, setOrderNumber] = useState<string>()

  useEffect(() => {
    dispatch(resetDeathInfo())
    dispatch(resetMarriageInfo())
    dispatch(resetBirthInfo())
    dispatch(resetActAddress())
    const searchParams = new URLSearchParams(location.search)
    const orderNumberFromUrl = searchParams.get('order_number')
    if (orderNumberFromUrl) setOrderNumber(orderNumberFromUrl)
  }, [location, dispatch])

  return (
    <div className='w-full md:w-4/5 p-2 md:p-4 m-2 md:m-4 flex flex-col items-start gap-3 text-customBlue text-xl bg-customLightBlue'>
      <b> Merci pour votre commande ! ✅ </b>
      <div>
        Votre demande d&#39;acte a été reçue et votre paiement a été validé avec
        succès. <br /> Nous vous remercions pour votre confiance. <br />
        Référence de la demande : <b> {orderNumber} </b> <br />
        Vous recevrez également une confirmation par e-mail avec les détails de
        votre demande.
      </div>
      <b> Prochaine étape : </b>
      <div>
        Votre dossier est en cours de traitement. Une fois votre acte disponible, il
        sera expédié directement à l’adresse indiquée par la mairie compétente.
        Le délai d’expédition peut varier selon la mairie, mais soyez assuré que
        votre demande sera traitée dès que possible.
      </div>
      <b> Besoin d&#39;informations supplémentaires ? </b>
      <div>
        Si vous avez des questions concernant votre demande, vous pouvez <Link to='/contact' className='font-bold underline'> nous contacter ici </Link> <br />
        Merci encore de nous avoir choisis pour simplifier vos démarches
        administratives !
      </div>
    </div>
  )
}

export default PaymentSuccess