import { createSlice, isRejectedWithValue } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ActeType, CookieState } from '../../data/interfaces.ts';
import { setBirthInfo } from "./birthActSlice.ts"
import { setMarriageInfo } from './marriageActSlice.ts';
import { setDeathInfo } from './deathActSlice.ts';
import { login } from '../thunks/login.thunk.ts';

interface AuthState {
  token?: string;
  actType?: ActeType;
  cookiesState: CookieState;
}

const initialState: AuthState = {
  token: '',
  cookiesState: CookieState.PENDING
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      delete state.token
    },
    acceptCookies(state) {
      state.cookiesState = CookieState.ACCEPTED
    },
    rejectCookies(state) {
      state.cookiesState = CookieState.REJECTED
    }
  },
  extraReducers(builder) {

    builder.addCase(setBirthInfo, (state, action) => {
      state.actType = ActeType.BIRTH
    })

    builder.addCase(setMarriageInfo, (state, action) => {
      state.actType = ActeType.MARRIAGE
    })

    builder.addCase(setDeathInfo, (state, action) => {
      state.actType = ActeType.DEATH
    })

    builder.addCase(login.fulfilled, (state, action) => {
      const { access_token } = action.payload
      state.token = access_token;
    })

    builder.addMatcher(
      isRejectedWithValue,
      (state, action: any) => {
        if (action.payload?.data?.code === 'expired_token') {
          state.token = '';
        }
      }
    );

  }
});

 export const authReducer = persistReducer({
  key: 'rtk:auth',
  storage,
  whitelist: ['token', 'actType', 'cookiesState']
}, authSlice.reducer);

export const { logout, acceptCookies, rejectCookies } = authSlice.actions;