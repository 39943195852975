import React from "react";
import { useParams } from "react-router-dom";
import { useFetchActDetailsQuery } from "../store/index.ts";
import { ActAddress, ActeType, BirthInfo, CivilityType, DeathInfo, MarriageInfo, PaymentInfo, TermAgreement } from "../data/interfaces.ts";

const labelClasses = "font-bold"

const BirthActDetails: React.FC<{  orderNumber: String, paymentInfo?: PaymentInfo,formattedActType: string, actAddressInfo: ActAddress, birthInfo: BirthInfo, agreements?: TermAgreement }> = (
  { orderNumber, paymentInfo, formattedActType, actAddressInfo, birthInfo, agreements }
) => {
  return (  
    <div className="flex flex-col">
      <div className="text-center font-semibold text-lg underline decoration-green-300/70 pb-2"> { `Acte de ${formattedActType}` } </div>
      <div className={labelClasses}> Détails de l'acte : </div>
      <div className="text-gray-900/90"> - <u>Référence</u> : { orderNumber } </div>
      <div className="text-gray-900/90"> - <u>Civilité</u> : { birthInfo.civility === CivilityType.MALE ? 'Homme' : 'Femme' } </div>
      <div className="text-gray-900/90"> - <u>Nom</u> : { birthInfo.lastName } </div>
      <div className="text-gray-900/90"> - <u>Prénom</u> : { birthInfo.firstName } </div>
      <div className="text-gray-900/90"> - <u>Date de naissance</u> : { birthInfo.birthDate } </div>
      <div className="text-gray-900/90"> - <u>Commune de naissance</u> : { birthInfo.birthPlace } </div>
      <div className="text-gray-900/90"> - <u>Pays</u> : { birthInfo.country } </div>
      {
        birthInfo.unknownFather 
          ? <div className="text-gray-900/90"> - <u>Père </u> : Inconnu </div>
          : (
            <>
              <div className="text-gray-900/90"> - <u>Nom du père</u> : { birthInfo.fathersLastName } </div>
              <div className="text-gray-900/90"> - <u>Prénom du père</u> : { birthInfo.fathersFirstName } </div>
            </>
          )
      }
      {
        birthInfo.unknownMother 
          ? <div className="text-gray-900/90"> - <u>Mère </u> : Inconnue </div>
          : (
            <>
              <div className="text-gray-900/90"> - <u>Nom de la mère</u> : { birthInfo.mothersLastName } </div>
              <div className="text-gray-900/90"> - <u>Prénom de la mère</u> : { birthInfo.mothersFirstName } </div>
            </>
          )
      }
      <div className="text-gray-900/90"> - <u>Relation avec la personne concerncée</u> : { birthInfo.relationship } </div>
      <div className="text-gray-900/90"> - <u>Format de l'acte</u> : { birthInfo.actFormat } </div>
      <div className="text-gray-900/90"> - <u>Motif de la demande</u> : { birthInfo.requestReason } </div>
      <div className={labelClasses}> Informations de livraison : </div>
      <div className="text-gray-900/90"> - <u>Civilité</u> : { actAddressInfo.civility === CivilityType.MALE ? 'Homme' : 'Femme' } </div>
      <div className="text-gray-900/90"> - <u>Nom</u> : { actAddressInfo.lastName } </div>
      <div className="text-gray-900/90"> - <u>Prénom</u> : { actAddressInfo.firstName } </div>
      <div className="text-gray-900/90"> - <u>Pays</u> : { actAddressInfo.country } </div>
      <div className="text-gray-900/90"> - <u>Ville</u> : { actAddressInfo.city } </div>
      <div className="text-gray-900/90"> - <u>Code Postal</u> : { actAddressInfo.postalCode } </div>
      <div className="text-gray-900/90"> - <u>Adresse</u> : { actAddressInfo.address } </div>
      <div className="text-gray-900/90"> - <u>Email</u> : { actAddressInfo.email } </div>
      <div className="text-gray-900/90"> - <u>Téléphone</u> : { actAddressInfo.phone } </div>
      <div className={labelClasses}> Termes générales : </div>
      <div className="text-gray-900/90"> - <u>Renonce aux droits de rétractation</u> : { agreements?.retractRetractionRight ? 'Oui' : 'Non' } </div>
      <div>  </div>
    </div>
  );
}

const DeathActDetails: React.FC<{  orderNumber: String, paymentInfo?: PaymentInfo,formattedActType: string, actAddressInfo: ActAddress, deathInfo: DeathInfo, agreements?: TermAgreement }> = (
  { orderNumber, paymentInfo, formattedActType, actAddressInfo, deathInfo, agreements }
) => {
  return (  
    <div className="flex flex-col">
      <div className="text-center font-semibold text-lg underline decoration-black/70 pb-2"> { `Acte de ${formattedActType}` } </div>
      <div className={labelClasses}> Détails de l'acte : </div>
      <div className="text-gray-900/90"> - <u>Référence</u> : { orderNumber } </div>
      <div className="text-gray-900/90"> - <u>Civilité</u> : { deathInfo.civility === CivilityType.MALE ? 'Homme' : 'Femme' } </div>
      <div className="text-gray-900/90"> - <u>Nom</u> : { deathInfo.lastName } </div>
      <div className="text-gray-900/90"> - <u>Prénom</u> : { deathInfo.firstName } </div>
      <div className="text-gray-900/90"> - <u>Date de décès</u> : { deathInfo.deathDate } </div>
      <div className="text-gray-900/90"> - <u>Commune de décès</u> : { deathInfo.deathPlace } </div>
      <div className="text-gray-900/90"> - <u>Pays</u> : { deathInfo.country } </div>
      <div className="text-gray-900/90"> - <u>Relation avec la personne concerncée</u> : { deathInfo.relationship } </div>
      <div className="text-gray-900/90"> - <u>Format de l'acte</u> : { deathInfo.actFormat } </div>
      <div className="text-gray-900/90"> - <u>Motif de la demande</u> : { deathInfo.requestReason } </div>
      <div className={labelClasses}> Informations de livraison : </div>
      <div className="text-gray-900/90"> - <u>Civilité</u> : { actAddressInfo.civility === CivilityType.MALE ? 'Homme' : 'Femme' } </div>
      <div className="text-gray-900/90"> - <u>Nom</u> : { actAddressInfo.lastName } </div>
      <div className="text-gray-900/90"> - <u>Prénom</u> : { actAddressInfo.firstName } </div>
      <div className="text-gray-900/90"> - <u>Pays</u> : { actAddressInfo.country } </div>
      <div className="text-gray-900/90"> - <u>Ville</u> : { actAddressInfo.city } </div>
      <div className="text-gray-900/90"> - <u>Code Postal</u> : { actAddressInfo.postalCode } </div>
      <div className="text-gray-900/90"> - <u>Adresse</u> : { actAddressInfo.address } </div>
      <div className="text-gray-900/90"> - <u>Email</u> : { actAddressInfo.email } </div>
      <div className="text-gray-900/90"> - <u>Téléphone</u> : { actAddressInfo.phone } </div>
      <div className={labelClasses}> Termes générales : </div>
      <div className="text-gray-900/90"> - <u>Renonce aux droits de rétractation</u> : { agreements?.retractRetractionRight ? 'Oui' : 'Non' } </div>
    </div>
  );
}

const MarriageActDetails: React.FC<{ orderNumber: String, paymentInfo?: PaymentInfo, formattedActType: String, actAddressInfo: ActAddress, marriageInfo: MarriageInfo, agreements?: TermAgreement }> = (
  { orderNumber, paymentInfo, formattedActType, actAddressInfo, marriageInfo, agreements }
) => {

  return (  
    <div className="flex flex-col"> 
      <div className="text-center font-semibold text-lg underline decoration-pink-400/80 pb-2"> { `Acte de ${formattedActType}` } </div>
      <div className={labelClasses}> Détails de l'acte : </div>
      <div className="text-gray-900/90"> - <u>Référence</u> : { orderNumber } </div>
      <div className="text-gray-900/90"> - <u>Date de mariage de l'acte</u> : { marriageInfo.marriageDate } </div>
      <div className="text-gray-900/90"> - <u>Commune de mariage</u> : { marriageInfo.marriagePlace } </div>
      <div className="text-gray-900/90"> - <u>Pays</u> : { marriageInfo.country } </div>
      <div className="text-gray-900/90"> - <u>Relation avec la personne concerncée</u> : { marriageInfo.relationship } </div>
      <div className="text-gray-900/90"> - <u>Format de l'acte</u> : { marriageInfo.actFormat } </div>
      <div className="text-gray-900/90"> - <u>Motif de la demande</u> : { marriageInfo.requestReason } </div>
      <div className={labelClasses}> Personne concerncée : </div>
      <div className="text-gray-900/90"> - <u>Civilité</u> : { marriageInfo.firstPerson.civility === CivilityType.MALE ? 'Homme' : 'Femme' } </div>
      <div className="text-gray-900/90"> - <u>Nom</u> : { marriageInfo.firstPerson.lastName } </div>
      <div className="text-gray-900/90"> - <u>Nom d'usage</u> : { marriageInfo.firstPerson.usageLastName } </div>
      <div className="text-gray-900/90"> - <u>Prénom</u> : { marriageInfo.firstPerson.firstName } </div>
      <div className="text-gray-900/90"> - <u>Date de naissance</u> : { marriageInfo.firstPerson.birthDate } </div>
      {
        marriageInfo?.firstPerson?.unknownFather 
          ? <div className="text-gray-900/90"> - <u>Père </u> : Inconnu </div>
          : (
            <>
              <div className="text-gray-900/90"> - <u>Nom du père</u> : { marriageInfo?.firstPerson?.fathersLastName } </div>
              <div className="text-gray-900/90"> - <u>Prénom du père</u> : { marriageInfo?.firstPerson?.fathersFirstName } </div>
            </>
          )
      }
      {
        marriageInfo?.firstPerson?.unknownMother 
          ? <div className="text-gray-900/90"> - <u>Mère </u> : Inconnue </div>
          : (
            <>
              <div className="text-gray-900/90"> - <u>Nom de la mère</u> : { marriageInfo?.firstPerson?.mothersLastName } </div>
              <div className="text-gray-900/90"> - <u>Prénom de la mère</u> : { marriageInfo?.firstPerson?.mothersFirstName } </div>
            </>
          )
      }
      <div className={labelClasses}> Son conjoint : </div>
      <div className="text-gray-900/90"> - <u>Civilité</u> : { marriageInfo.secondPerson.civility === CivilityType.MALE ? 'Homme' : 'Femme' } </div>
      <div className="text-gray-900/90"> - <u>Nom</u> : { marriageInfo.secondPerson.lastName } </div>
      <div className="text-gray-900/90"> - <u>Nom d'usage</u> : { marriageInfo.secondPerson.usageLastName } </div>
      <div className="text-gray-900/90"> - <u>Prénom</u> : { marriageInfo.secondPerson.firstName } </div>
      <div className="text-gray-900/90"> - <u>Date de naissance</u> : { marriageInfo.secondPerson.birthDate } </div>
      {
        marriageInfo?.secondPerson?.unknownFather 
          ? <div className="text-gray-900/90"> - <u>Père </u> : Inconnu </div>
          : (
            <>
              <div className="text-gray-900/90"> - <u>Nom du père</u> : { marriageInfo?.secondPerson?.fathersLastName } </div>
              <div className="text-gray-900/90"> - <u>Prénom du père</u> : { marriageInfo?.secondPerson?.fathersFirstName } </div>
            </>
          )
      }
      {
        marriageInfo?.secondPerson?.unknownMother 
          ? <div className="text-gray-900/90"> - <u>Mère </u> : Inconnue </div>
          : (
            <>
              <div className="text-gray-900/90"> - <u>Nom de la mère</u> : { marriageInfo?.secondPerson?.mothersLastName } </div>
              <div className="text-gray-900/90"> - <u>Prénom de la mère</u> : { marriageInfo?.secondPerson?.mothersFirstName } </div>
            </>
          )
      }
      <div className={labelClasses}> Informations de livraison : </div>
      <div className="text-gray-900/90"> - <u>Civilité</u> : { actAddressInfo.civility === CivilityType.MALE ? 'Homme' : 'Femme' } </div>
      <div className="text-gray-900/90"> - <u>Nom</u> : { actAddressInfo.lastName } </div>
      <div className="text-gray-900/90"> - <u>Prénom</u> : { actAddressInfo.firstName } </div>
      <div className="text-gray-900/90"> - <u>Pays</u> : { actAddressInfo.country } </div>
      <div className="text-gray-900/90"> - <u>Ville</u> : { actAddressInfo.city } </div>
      <div className="text-gray-900/90"> - <u>Code Postal</u> : { actAddressInfo.postalCode } </div>
      <div className="text-gray-900/90"> - <u>Adresse</u> : { actAddressInfo.address } </div>
      <div className="text-gray-900/90"> - <u>Email</u> : { actAddressInfo.email } </div>
      <div className="text-gray-900/90"> - <u>Téléphone</u> : { actAddressInfo.phone } </div>
      <div className={labelClasses}> Termes générales : </div>
      <div className="text-gray-900/90"> - <u>Renonce aux droits de rétractation</u> : { agreements?.retractRetractionRight ? 'Oui' : 'Non' } </div>
    </div>
  );
}
interface ActDetailsProps {
  actId: string
}

const ActDetails: React.FC<ActDetailsProps> = ({ actId }) => {

  const { 
    data: actDetails
  } = useFetchActDetailsQuery(actId)

  if (!actDetails) return <></>

  const renderActDetails = () => {
    switch (actDetails.actType) {
      case ActeType.MARRIAGE:
        return <MarriageActDetails orderNumber={actDetails.orderNumber} paymentInfo={actDetails.paymentInfo} formattedActType={actDetails.formattedActType} marriageInfo={actDetails.marriageInfo} actAddressInfo={actDetails.actAddressInfo} agreements={actDetails.agreements} />;
      case ActeType.BIRTH:
        return <BirthActDetails orderNumber={actDetails.orderNumber} paymentInfo={actDetails.paymentInfo} formattedActType={actDetails.formattedActType} birthInfo={actDetails.birthInfo} actAddressInfo={actDetails.actAddressInfo} agreements={actDetails.agreements} />;
      case ActeType.DEATH:
        return <DeathActDetails orderNumber={actDetails.orderNumber} paymentInfo={actDetails.paymentInfo} formattedActType={actDetails.formattedActType} deathInfo={actDetails.deathInfo} actAddressInfo={actDetails.actAddressInfo} agreements={actDetails.agreements} />;
      default:
        return null;
    }
  }

  return (  
    <div className="w-4/5 md:w-3/5 bg-white p-8 m-4">
      { renderActDetails() }
    </div>
  );
}

const Wrapper = () => {
  const { actId } = useParams();
  if (!actId) return <></>

  return <ActDetails actId={actId} />
}
 
export default Wrapper;