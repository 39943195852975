import React from "react";

const LegalNotice = () => {
  return (
    <div className="w-full md:w-4/5 flex flex-col gap-3 p-4 text-customBlue bg-customLightBlue m-2">
      <h2 className="font-bold"> Mentions légales : </h2>
      <h3 className="font-bold"> Éditeur du site : </h3>
      <div>
        Le site internet www. acte-naissance-en-ligne.fr est édité par EOS
        MarKeting EI immatriculée au RCS de Périgueux sous le numéro
        751266438 dont le siège social est Le Lys de Cristal 240 Route de Dordogne 24250 Groléjac– FRANCE. Mme A. Beunard assure la direction de la
        publication.
      </div>
      <div>
        Mail de contact : service-client@acte-naissance-en-ligne.fr
      </div>
      <h3 className="font-bold">
        Hébergeur du Site :
      </h3>
      <div>
        Le nom de l’hébergeur : O2SWITCH
      </div>
      <div>
        L’adresse : 222-224 Boulevard Gustave Flaubert 63000 Clermont-Ferrand
      </div>
      <div>
        le capital Social: 100 000€
      </div>
      <div>
        Le numéro SIRET : 510 909 80700024
      </div>
      <div>
        RCS : Clermont-Ferrand
      </div>
    </div>
  );
}

export default LegalNotice