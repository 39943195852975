import React, { useEffect, useState } from "react";
import { TbMapPinSearch } from "react-icons/tb";
import SearchBar from "../components/common/SearchBar.tsx";
import { useFetchMunicipalityDetailsQuery } from "../store/index.ts";
import { Link } from "react-router-dom";
import Map from "../components/common/Map.jsx"
import { setMetaTags } from "../data/helpers.tsx";

const MunicipalitySearch = () => {

  const [mairie, setMairie] = useState<string>('')

  const {
    data: municipalityDetails
  } = useFetchMunicipalityDetailsQuery(mairie, { skip: !mairie })

  useEffect(() => {
    setMetaTags(
      "Trouvez votre mairie de naissance | acte-naissance-en-ligne.fr",
      "Trouvez les coordonnées de votre mairie de naissance en recherchant par commune. Obtenez rapidement les informations administratives nécessaires.",
      'https://www.acte-naissance-en-ligne.fr/recherche-mairie'
    );
  }, []);

  const handleChange = (_, value: string) => {
    setMairie(value.substring(0, value.length - 5))
  }

  const actClasses = "hover:-translate-y-1 cursor-pointer underline text-lg"

  return (
    <div className="w-full md:w-4/5 bg-customLightBlue text-customBlue p-8 m-2 flex flex-col items-center gap-2">
      <h1 className="p-4 font-bold text-customBlue">
        Trouvez les coordonnées de votre mairie de naissance
      </h1>
      <SearchBar
        id="birthPlace"
        value={mairie}
        label="Recherche par commune"
        placeholder="commune."
        labelIcon={TbMapPinSearch}
        setFieldValue={handleChange}
        optionPrefix="Mairie de"
      />

      {
        municipalityDetails && (
          <div className="flex flex-col gap-2 w-full my-2 z-10 text-justify">
            {
              municipalityDetails.address && <div>  Mairie de <strong> {municipalityDetails.name} </strong> {`se situe ${municipalityDetails.address}, ${municipalityDetails.postalCode}, ${municipalityDetails.city} (${municipalityDetails.department} ${municipalityDetails.departmentCode})`} </div>
            }
            {municipalityDetails.email && <div> {municipalityDetails.email} </div>}
            {municipalityDetails.webSite && <div> <a href={municipalityDetails.webSite} className="underline"> {municipalityDetails.webSite} </a> </div>}

            <Map center={[municipalityDetails.coordinates.lat, municipalityDetails.coordinates.lon]} markerTitle={municipalityDetails.name} />

            <div className="flex flex-col text-jusitfy gap-3 p-3 my-2">
              <p>
                Vous avez besoin d’un acte d’état civil pour vos démarches administratives à {municipalityDetails.city} ? La mairie de {municipalityDetails.name} est à votre disposition. Ses officiers d’états civil vous accueillent aux horaires d’ouverture pour vous fournir l’acte d’état civil nécessaire, qu’il s’agisse d’un acte de naissance, d’un acte de mariage ou d’un acte de décès.
              </p>
              <p>
                Pour plus de confort et de rapidité, commandez vos actes d’états civils en ligne en toute sécurité.  En quelque clic, sans quitter votre domicile, obtenez vos documents grâce à nos formulaires intuitifs.
              </p>
              <strong className="self-center text-xl"> Simplifiez vos formalités ! </strong>
            </div>

            <div className="flex flex-col md:flex-row w-full justify-evenly text-center">
              <Link to={`/acte-de-naissance/${municipalityDetails.name}`} > <div className={`${actClasses} decoration-green-400/50`}> Commander un acte de naissance </div> </Link>
              <Link to={`/acte-de-mariage/${municipalityDetails.name}`} > <div className={`${actClasses} decoration-pink-400/50`}> Commander un acte de mariage </div> </Link>
              <Link to={`/acte-de-deces/${municipalityDetails.name}`} > <div className={`${actClasses} decoration-black/50`}> Commander un acte de décès </div> </Link>
            </div>

          </div>
        )
      }

      <div className="w-full flex flex-col gap-3 mt-4 bg-customLightBlue p-2 rounded">
        <h2 className="font-bold"> Les Mairies les plus recherchées pour les actes d’état civil : </h2>
        <div>
          En France, certaines mairies se distinguent par le nombre croissant de demandes pour la
          délivrance d&#39;actes d&#39;état civil. Que ce soit pour des actes de naissance, de mariage ou de
          décès, ces villes incarnent des pôles incontournables, attirant l&#39;attention non seulement de
          leurs habitants, mais aussi d&#39;une population plus large, à la recherche de leurs précieuses
          archives.
        </div>
        <div>
          Ces mairies, par leur histoire, leur dynamisme ou leur position stratégique, incarnent
          aujourd’hui les centres névralgiques des démarches administratives en France. Elles
          représentent bien plus que des points d’accès à des documents officiels ; elles sont les
          gardiennes de nos histoires personnelles, et les témoins silencieux des moments clés de nos
          vies.
        </div>
        <div>
          <h3><b>Paris</b></h3>
          <div>Naturellement en tête de liste. La capitale, avec ses 20 arrondissements, concentre à elle seule une part significative des demandes d’actes d’état civil.</div>
        </div>

        <div>
          <h3><b>Marseille</b></h3>
          <div>La cité phocéenne. Deuxième ville de France, son caractère cosmopolite et son histoire millénaire en font un point central pour la délivrance de documents officiels.</div>
        </div>

        <div>
          <h3><b>Lyon</b></h3>
          <div>Avec son statut de métropole dynamique, Lyon est également très demandée pour la délivrance d'actes d'état civil.</div>
        </div>

        <div>
          <h3><b>Toulouse</b></h3>
          <div>La "Ville Rose" est une autre grande ville française où les demandes d'actes d'état civil sont fréquentes.</div>
        </div>

        <div>
          <h3><b>Nice</b></h3>
          <div>En tant que centre touristique et ville historique, Nice voit de nombreuses demandes pour ses archives d'état civil.</div>
        </div>

        <div>
          <h3><b>Nantes</b></h3>
          <div>Ville en pleine expansion, Nantes est de plus en plus sollicitée pour les actes d'état civil.</div>
        </div>

        <div>
          <h3><b>Strasbourg</b></h3>
          <div>Capitale européenne, Strasbourg reçoit un grand nombre de demandes, notamment en raison de sa proximité avec l'Allemagne.</div>
        </div>

        <div>
          <h3><b>Montpellier</b></h3>
          <div>Ville dynamique et étudiante, Montpellier est également une mairie très demandée pour les actes d'état civil.</div>
        </div>

        <div>
          <h3><b>Bordeaux</b></h3>
          <div>Connu pour son patrimoine et son vin, Bordeaux est une autre ville où les demandes d'actes d'état civil sont fréquentes.</div>
        </div>

        <div>
          <h3><b>Lille</b></h3>
          <div>Située près de la Belgique, Lille voit un grand nombre de demandes pour les actes de naissance, de mariage et de décès.</div>
        </div>

        <div>
          <h3><b>Rennes</b></h3>
          <div>Capitale de la Bretagne, Rennes est une ville clé pour les recherches d'état civil.</div>
        </div>

        <div>
          <h3><b>Le Havre</b></h3>
          <div>Important port maritime, Le Havre reçoit également de nombreuses demandes d'actes d'état civil.</div>
        </div>

        <div>
          <h3><b>Saint-Étienne</b></h3>
          <div>Autre ville de la région Auvergne-Rhône-Alpes, Saint-Étienne est sollicitée pour les actes d'état civil.</div>
        </div>

        <div>
          <h3><b>Toulon</b></h3>
          <div>Avec sa base navale, Toulon voit de nombreuses demandes, notamment pour des actes liés à des familles de marins.</div>
        </div>

        <div>
          <h3><b>Grenoble</b></h3>
          <div>Située au pied des Alpes, Grenoble est une ville active où les demandes d'actes d'état civil sont nombreuses.</div>
        </div>

        <div>
          <h3><b>Reims</b></h3>
          <div>Ville historique et capitale du champagne, Reims reçoit également de nombreuses demandes pour ses archives d'état civil.</div>
        </div>

        <div>
          <h3><b>Dijon</b></h3>
          <div>Capitale de la Bourgogne, Dijon est une autre ville où les actes d'état civil sont fréquemment demandés.</div>
        </div>

        <div>
          <h3><b>Angers</b></h3>
          <div>Ville historique de la Loire, Angers est sollicitée pour les actes d'état civil, en raison de son riche passé.</div>
        </div>

        <div>
          <h3><b>Nîmes</b></h3>
          <div>Ville au patrimoine romain remarquable, Nîmes reçoit également de nombreuses demandes pour ses documents d'état civil.</div>
        </div>

        <div>
          <h3><b>Aix-en-Provence</b></h3>
          <div>Ville d'art et d'histoire, Aix-en-Provence est une mairie très recherchée pour les actes d'état civil.</div>
        </div>
      </div>

    </div>
  );
}

export default MunicipalitySearch;