import { StepStyleDTO } from "react-form-stepper/dist/components/Step/StepTypes";

export const stepperStyleConfig: StepStyleDTO = {
  activeBgColor: "transparent",
  activeTextColor: "#ffffff",
  completedBgColor: "transparent",
  completedTextColor: "#ffffff",
  inactiveBgColor: "transparent",
  inactiveTextColor: "#ffffff",
  size: "2.5em",
  circleFontSize: "1rem",
  labelFontSize: "0.875rem",
  borderRadius: "50%",
  fontWeight: "600"
}

export const getColorByStep = (max: number, activeStep: number) => {
  if (activeStep < max) return 'bg-[#A9A9A9]'
  else if (activeStep === max) return 'bg-[#6495ED]'
  else return 'bg-[#008000]'
}