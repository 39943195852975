import React from "react"
import { Link } from "react-router-dom"

const Footer: React.FC<{}> = () => {

  return (
    <div className="w-full bg-customBlue relative flex justify-center p-4 z-10">
      <div className="w-full md:w-2/3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 p-4 text-white font-semibold justify-items-start md:justify-items-center" >
        <div className="flex flex-col">
          <Link to="/acte-de-naissance" > <div className="hover:-translate-y-1 cursor-pointer"> Acte de naissance </div> </Link>
          <Link to="/acte-de-mariage" > <div className="hover:-translate-y-1 cursor-pointer"> Acte de mariage </div> </Link>
          <Link to="/acte-de-deces" > <div className="hover:-translate-y-1 cursor-pointer"> Acte de décès </div> </Link>
          <Link to="/demarche" > <div className="hover:-translate-y-1 cursor-pointer"> Vos démarches </div> </Link>
          <Link to="/recherche-mairie" > <div className="hover:-translate-y-1 cursor-pointer"> Info mairie </div> </Link>
        </div>
        <div className="flex flex-col">
          <Link to="/politique-de-confidentialite" > <div className="hover:-translate-y-1 cursor-pointer"> Politiques de confidentialité </div> </Link>
          <Link to="/mentions-legales" > <div className="hover:-translate-y-1 cursor-pointer"> Mentions légales </div> </Link>
          <Link to="/cgv" > <div className="hover:-translate-y-1 cursor-pointer"> CGV </div> </Link>
          <Link to="/contact" > <div className="hover:-translate-y-1 cursor-pointer"> Contact </div> </Link>
        </div>  
      </div>
    </div>
  )
}
 
export default Footer