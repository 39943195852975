import React, { useState } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../store/index.ts";

interface CheckoutProps {
  onClose: () => void;
  setDoneTitle: (title: boolean) => void;
  handlePayPalCheckout: (step: string) => void;
}

const Checkout: React.FC<CheckoutProps>  = ({ handlePayPalCheckout, onClose, setDoneTitle }) => {
  
  const navigate = useNavigate()
  const actAddress = useTypedSelector((state) => state.actAddress)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onCreateOrder = (data, actions) => {
    setIsLoading(true)
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: "1.00",
            currency_code: "EUR",
            breakdown: {
              item_total: {
                currency_code: "EUR",
                value: "1.00",
              },
            },
          },
          items: [
            {
              name: "Acte d'état civil",
              unit_amount: {
                currency_code: "EUR",
                value: "1.00",
              },
              quantity: "1",
            },
          ]
        },
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING"
      },
      payer: {
        name: {
          given_name: actAddress.firstName,
          surname: actAddress.lastName
        },
        email_address: actAddress.email,
        phone: {
          phone_type: "MOBILE",
          phone_number: {
            national_number: actAddress.phone?.slice(1) || ''
          }
        }
      }
    }).then(async (orderId) => {
      setIsLoading(false)
      await handlePayPalCheckout(orderId);
      return orderId;
    }).catch(error => { setIsLoading(false) });
  };

  const onApproveOrder = (data,actions) => {
    setDoneTitle(true)
    setIsLoading(true)
    return actions.order.capture().then((details) => {
      setIsLoading(true);
      fetch(process.env.REACT_APP_API_URL + '/payments/paypal/approuve-order/' + data.orderID)
      .then(async (response) => {
        const resp = await response.json()
        navigate('/success?order_number=' + resp.act.orderNumber)
      })
      .catch((error) => console.error('Error verifying session:', error));
    });
  }

  return (  
    <div className="relative">
      <div className="p-4">
        <PayPalButtons 
          style={{ layout: "vertical", }}
          createOrder={(data, actions) => onCreateOrder(data, actions)}
          onApprove={(data, actions) => onApproveOrder(data, actions)}
        />
      </div>
      { isLoading && <div className="w-full bg-gray-200 flex z-50"> <img src="/images/loading-buffering.gif" alt="loading" className="mx-auto w-1/5" /> </div> }
    </div>
  );
}
 
export default Checkout;