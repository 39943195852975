import React, { ReactNode, useState } from "react";
import Modal from "../common/Modal.tsx";
import { useTypedSelector } from "../../store/index.ts";
import { ActAddress, ActeType, AddAct, BirthInfo, DeathInfo, MarriageInfo, TermAgreement } from "../../data/interfaces.ts";
import Checkout from "../paiement/Checkout.tsx";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

interface PaiementMethodProps {
  onClose: () => void;
  setActiveStep: (step: number) => void;
  agreements: TermAgreement;
}

const PaiementMethod: React.FC<PaiementMethodProps> = ({ onClose, setActiveStep, agreements }) => {

  const actType: ActeType | undefined = useTypedSelector((state) => state.auth.actType)

  const [isDoneTitle, setDoneTitle] = useState<boolean>(false)
  const { marriageInfo, birthInfo, deathInfo, actAddressInfo } = useTypedSelector(
    (state) => ({
      marriageInfo: state.marriageAct,
      birthInfo: state.birthAct,
      deathInfo: state.deathAct,
      actAddressInfo: state.actAddress,
      actType: state.auth.actType
    })
  )

  const handlePayPalCheckout = async (orderId: string) => {
    try {
      if (actType && actAddressInfo) {
        let requestBody: AddAct = { actType, actAddressInfo: actAddressInfo as Required<ActAddress>, agreements }
        if (actType === ActeType.BIRTH) requestBody.birthInfo = birthInfo as Required<BirthInfo>
        else if (actType === ActeType.DEATH) requestBody.deathInfo = deathInfo as Required<DeathInfo>
        else if (actType === ActeType.MARRIAGE) requestBody.marriageInfo = marriageInfo as Required<MarriageInfo>
        await fetch(process.env.REACT_APP_API_URL + '/payments/paypal/create-order', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...requestBody, paypalOrderId: orderId }),
        });
      }
    } catch (err) {
      console.error('Error during checkout:', err);
    }
  };

  const initialOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "EUR",
    intent: "capture",
    locale: "fr_FR"
  };

  const modalContent: ReactNode = (
    <div 
      className="bg-white p-8 rounded flex flex-col gap-2 overflow-y-auto"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="font-semibold text-lg">
        {
          isDoneTitle ? (
            <>
              <div> Merci pour votre commande ! </div>
              <div> Nous sommes ravis de vous compter parmi nos clients. </div>
            </>
          )
          : 'Choisissez votre mode de paiement'
        }
      </div>
      <div
        className="rounded p-2 items-center cursor-pointer justify-between"
      >
        <div className="flex gap-2 justify-center items-center">
          <PayPalScriptProvider options={initialOptions}>
            <Checkout handlePayPalCheckout={handlePayPalCheckout} onClose={onClose} setDoneTitle={setDoneTitle} />
          </PayPalScriptProvider>
        </div>
      </div>
    </div>
  )

  return (
    <Modal
      onClose={onClose}
    >
      {modalContent}
    </Modal>
  );
}

export default PaiementMethod;