// components imports
import Header from "../components/layout/Header.tsx";
import Footer from "../components/layout/Footer.tsx";

// React Related Imports
import React, { useEffect } from "react";
import {  Outlet, useLocation } from "react-router-dom";
import { logout, acceptCookies, rejectCookies, useTypedDispatch, useTypedSelector } from "../store/index.ts";
import Button from "../components/common/Button.tsx";
import { CookieState } from "../data/interfaces.ts";

const ScrollToTop: React.FC<{}> = () => {

  // hooks
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const MainLayout: React.FC<{}> = () => {

  const dispatch = useTypedDispatch()
  const { token, cookiesState } = useTypedSelector(state => state.auth)

  const handleLogout = () => {
    dispatch(logout())
  }

  const handleAcceptCookies = () => {
    dispatch(acceptCookies())
  }

  const handleRejectCookies = () => {
    dispatch(rejectCookies())
  }

  // renders
  return (
    <div className="w-full flex flex-col items-center">
      <Header />
      <ScrollToTop />
      {token && (
        <Button
          className="self-end p-2 m-2 rounded border-customBlue text-customBlue"
          onClick={handleLogout}
        >
          Se déconnecter
        </Button>
      )
      }
      <div className="w-full mt-2 min-h-screen flex flex-col items-center">
        <Outlet />
      </div>
      {
        cookiesState === CookieState.PENDING && (
          <div className="fixed bottom-0 left-0 right-0 bg-gray-200 p-4 gap-2 shadow-lg z-20 flex flex-col">
            <div className="font-bold text-xl">
              Cookies sur acte-naissance-en-ligne.fr
            </div>
            <div>
              Nous utilisons certains cookies essentiels pour faire fonctionner ce service.
            </div>
            <div>
              Nous aimerions également utiliser des cookies d’analyse pour comprendre comment vous utilisez le service et apporter des améliorations.
            </div>
            <div className="flex ml-auto gap-2 mt-8">
              <button
                className="bg-green-800 text-white px-4 py-2"
                onClick={handleAcceptCookies}
              >
                Accepter les cookies d’analyse
              </button>
              <button
                className="bg-green-800 text-white px-4 py-2"
                onClick={handleRejectCookies}
              >
                Refuser les cookies d’analyse
              </button>
            </div>
            {/* <div className="container mx-auto flex justify-between items-center">
              <p className="text-sm">
                Nous utilisons certains cookies essentiels pour faire fonctionner ce service.
                Nous aimerions également utiliser des cookies d’analyse pour comprendre comment vous utilisez le service et apporter des améliorations.
                <Link to='/mentions-legales' className="underline text-blue-400 hover:text-blue-300" >Lisez notre politique de confidentialité </Link>
              </p>
              <button 
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                onClick={handleAcceptCookies}
              >
                Accepter
              </button>
            </div> */}
          </div>
        )
      }

      <Footer />
    </div>
  );
};

export default MainLayout;
